<script>
import { VRow, VCol, VSpacer, VCard, VContainer, VDivider, VIcon, VFileInput, Intersect } from 'vuetify/lib'
export default {
	components: {
		VRow,
		VCol,
		VSpacer,
		VCard,
		VContainer,
		VDivider,
		VIcon,
		VFileInput,
	},
	props: {
		data: Object,
		dynamicData: Array,
		methodsCode: String,
		mountedCode: String,
		beforeDestroyCode: String,
		dataProps: String,
		cssStyles: String,
		topLevel: {
			type: Boolean,
			default: false,
		},
		cid: Number,
	},
	metaInfo() {
		if (this.cssStyles)
			return {
				style: [
					{
						vmid: `dynamic-dpc-style-${this.cid}`,
						type: 'text/css',
						cssText: this.cssStyles,
					},
				],
			}
	},
	directives: {
		intersect: Intersect,
	},
	data() {
		if (!this.dataProps) return {}
		return new Function(`return ${this.dataProps}`).call(this)
	},
	created() {
		let methods = new Function(`return ${this.methodsCode}`).call(this)
		for (let key in methods) {
			this[key] = methods[key].bind(this)
		}
	},
	mounted() {
		if (!this.mountedCode) return
		new Function(`${this.mountedCode}`).call(this)
	},
	beforeDestroy() {
		if (!this.beforeDestroyCode) return
		new Function(`${this.beforeDestroyCode}`).call(this)
	},
	methods: {
		getAttrs(data) {
			let acc = {}
			if (!data.attributes) return acc
			let attrs = data.attributes.filter((attr) => !attr.key.startsWith('@') && !attr.key.startsWith('v-'))

			for (let x of attrs) {
				if (x.key.startsWith(':')) {
					let value = new Function(`return ${x.value}`).call(this)
					let key = x.key.slice(1)
					if (key === 'class') {
						acc[key] = { ...(acc[key] || {}), ...value }
					} else {
						acc[key] = value
					}
				} else {
					if (x.key === 'class') {
						acc[x.key] = { ...(acc[x.key] || {}), [x.value]: true }
					} else if (x.key === 'style') {
						acc[x.key] = acc[x.key] || x.value
					} else {
						acc[x.key] = x.value
					}
				}
			}

			let id = attrs.find((attr) => attr.key === 'id')
			if (id) {
				let dynamicData = this.dynamicData.find((d) => d.id === id.value)
				if (dynamicData) {
					Object.assign(acc, dynamicData.attrs)
				}
			}

			return acc
		},
		getEvents(data) {
			let acc = {}

			for (let x of data.attributes || []) {
				if (x.key.startsWith('@')) {
					acc[x.key.slice(1)] = new Function('$event', `${x.value}`).bind(this)
				}
			}

			return acc
		},
		getString(str) {
			return str.replace(/\{\{(.*?)\}\}/g, (match, p1) => {
				return new Function(`return ${p1}`).call(this)
			})
		},
		getIfValue(data) {
			let found = data.attributes?.find((attrs) => attrs.key === 'v-if')
			return found ? new Function(`return ${found.value}`).call(this) : true
		},
		getDirectives(data) {
			let commonDirectives = ['v-if', 'v-for', 'v-else']
			let rawDirectives = data.attributes?.filter(
				(attrs) => attrs.key.startsWith('v-') && !commonDirectives.includes(attrs.key)
			)
			if (!rawDirectives) return
			return rawDirectives.map((rawDirective) => {
				return {
					name: rawDirective.key.split('.')[0].slice(2),
					value: new Function(`return ${rawDirective.value}`).call(this),
					modifiers: rawDirective.key
						.split('.')
						.slice(1)
						.reduce((acc, x) => ({ ...acc, [x]: true }), {}),
				}
			})
		},
	},
	render(h) {
		let getElement = (data, n) => {
			let { class: classes, style, ...attrs } = this.getAttrs(data)
			return h(
				data.tagName || 'div',
				{
					key: n,
					attrs,
					class: classes,
					style,
					on: this.getEvents(data),
					directives: this.getDirectives(data),
				},
				[...getChildren(data.children || [])]
			)
		}

		let getChildren = (elements) => {
			return elements.map((child, n) => {
				if (child.type === 'element' && this.getIfValue(child)) {
					return getElement(child, n)
				} else {
					return child.type === 'text' ? this.getString(child.content) : ''
				}
			})
		}

		return getElement(this.data)
	},
}
</script>
